import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import CustomerLogo from "../components/CustomerLogo"
import CalendlyEmbed from "../components/CalendlyEmbed"
import gigasetLogo from "../svg/gigaset-logo-color.svg"
import loreallogo from "../svg/loreal-logo.svg"
import telefonicaLogo from "../svg/telefonica-logo.svg"
import { formatPrismicLang } from "../utils/formatPrismicLang"

const Pricing = ({ data }) => {
  const prismicNode = data.allPrismicPricing.edges.slice(0, 1).pop().node
  const pricingData = prismicNode.data
  const lang = formatPrismicLang(prismicNode)

  const calendlyEventName =
    lang === "en"
      ? "discovery-call-onsite-personalisierung-en"
      : "discovery-call-onsite-personalisierung"

  return (
    <Layout language={lang} smoothScrolling={false}>
      <div className="py-4 relative bg-white">
        <div className="max-w-7xl mx-auto lg:grid lg:grid-cols-5">
          <div className="py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="max-w-lg mx-auto">
              <h2 className="text-2xl leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
                {pricingData.heading.text}
              </h2>
              <p className="mt-8 text-lg leading-6 text-gray-600">
                <span className="md:hidden">
                  {pricingData.text_1.text}
                  <br />
                  {pricingData.text_2.text}
                </span>
                <span className="hidden md:inline">
                  {pricingData.text_1.text}
                  <br />
                  <br />
                  {pricingData.text_3.text}
                </span>
              </p>
              <div className="container mx-auto">
                <p className="mt-8 text-gray-600">
                  {pricingData.logo_heading.text}
                </p>
                <div className="flex flex-row">
                  <CustomerLogo src={loreallogo} />
                  <CustomerLogo src={telefonicaLogo} />
                  <CustomerLogo src={gigasetLogo} />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white px-4 sm:px-6 lg:col-span-3 lg:px-8 xl:pl-12">
            {/* Calendly inline widget begin */}
            <CalendlyEmbed account="trbo-de" eventName={calendlyEventName} />
            {/* Calendly inline widget end */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PricingQuery($lang: String) {
    allPrismicPricing(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          lang
          data {
            heading {
              text
            }
            text_1 {
              text
            }
            text_2 {
              text
            }
            text_3 {
              text
            }
            logo_heading {
              text
            }
          }
        }
      }
    }
  }
`

export default Pricing
