import React from 'react';

const CustomerLogo = ({ src }) => (
  <>
    <div className="sm:w-2/4">
      <img className="w-5/6 h-20" src={src}/>
    </div>
  </>
);

export default CustomerLogo;
